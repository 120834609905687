import React from 'react'
import { Link } from 'gatsby';

import styles from './Footer.module.scss';

const Footer = () => (
  <footer className={styles.container}>

    <section>
      <nav>
        <Link to="/" activeClassName={styles.active}>Home</Link>
        <Link to="/about" activeClassName={styles.active}>About</Link>
        <Link to="/services" activeClassName={styles.active}>Services</Link>
        <Link to="/contact" activeClassName={styles.active}>Contact</Link>
      </nav>
    </section>

    <section>
      &copy; G Parkinson Maintenance Ltd 2020
    </section>
  </footer>
)

export default Footer
