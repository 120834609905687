import React from "react"
import { Helmet } from "react-helmet"
import { useStaticQuery, graphql } from 'gatsby';

function SEO({ description, meta, title, structuredData }) {
  const desc = description || 'With years of experience, we provide Leicester and the Midlands with not only Glass and Glazing services; but Painting, Decorating and Plastering services too!';
  const data = useStaticQuery(graphql`
  query {
    test: file(absolutePath: { regex: "/window.jpg/" }) { childImageSharp { fixed(width: 1200, height: 628, cropFocus: EAST) { src }}},
  }`);
  
  return (
    <Helmet
      htmlAttributes={{ lang: 'en'}}
      title={title || `Leicester's Glazing, Plastering & Decorating Experts`}
      titleTemplate={`%s | G Parkinson Maintenance`}
      meta={[
        { name: `viewport`, content: `width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no` },
        { name: `description`, content: desc },
        { property: `og:title`, content: title },
        { property: `og:description`, content: desc },
        { property: `og:type`, content: `website` },
        { property: `og:image`, content: `https://gp-maintenance.co.uk${data.test.childImageSharp.fixed.src}` },
        { name: `twitter:card`, content: `summary` },
        { name: `twitter:title`, content: title },
        { name: `twitter:description`, content: desc },
        { name: `theme-color`, content: '#6FC9EF' },
      ].concat(meta || [])}
    >
      {structuredData && <script type="application/ld+json">{JSON.stringify(structuredData)}</script>}
    </Helmet>
  )
}

export default SEO