import React, { useEffect, useState } from 'react'
import debounce from 'lodash.debounce';
import { Header, Footer } from '..';

import styles from './Layout.module.scss';

const Layout = ({ children, headerBreakpoint = 330 }) => {
  const [isTransparent, setIsTransparent ] = useState(true);

  const listenToScroll = debounce(() => {
    const winScroll = document.body.scrollTop || document.documentElement.scrollTop;

    setIsTransparent(winScroll <= headerBreakpoint);
  }, 35, { maxWait: 150})

  useEffect(() => {
    window.addEventListener('scroll', listenToScroll);

    return () => window.removeEventListener('scroll', listenToScroll)
  }, [listenToScroll, setIsTransparent])


  return (
    <>
      <Header isTransparent={isTransparent} />

      <main className={styles.layout}>
        {children}
      </main>

      <Footer />
    </>
  )
}

export default Layout
