import React from 'react'
import Img from 'gatsby-image';

import styles from './Card.module.scss';

const Card = ({image, children}) => (
  <div className={styles.card}>
    <Img fluid={image.childImageSharp.fluid} className={styles.img}/>

    <div>
      {children}
    </div>
  </div>
)

export default Card
