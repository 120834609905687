import React, { useState } from 'react'
import { Link, useStaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';

import styles from './Header.module.scss';

const Header = ({ isTransparent }) => {
  const [isOpen, setIsOpen] = useState(false);
  const data = useStaticQuery(graphql`
    query {
      logo: file(absolutePath: { regex: "/logo.png/" }) { childImageSharp { fluid(maxWidth: 320) { ...GatsbyImageSharpFluid_withWebp }}},
      mobileMenu: file(absolutePath: { regex: "/menu.svg/" }) { publicURL },
    }
  `);

  return (
    <header className={`${styles.header} ${isTransparent ? styles.isTransparent : ''} ${isOpen ? styles.navOpen : ''}`}>
      
      <Link to="/">
        <Img className={styles.logo} fluid={data.logo.childImageSharp.fluid} />
      </Link>

      <button type="button" aria-label="Toggle Menu" style={{backgroundImage: `url('${data.mobileMenu.publicURL}')`}} onClick={() => setIsOpen(!isOpen)}></button>
      
      <nav>
        <ul>
          <li>
            <Link to="/about" activeClassName={styles.active}>About</Link>
          </li>
          <li>
            <Link to="/services" activeClassName={styles.active}>Services</Link>
          </li>
          <li>
            <Link to="/contact" activeClassName={styles.active}>Contact</Link>
          </li>
        </ul>
      </nav>

      <div className={styles.navOverlay} onClick={() => setIsOpen(!isOpen)}/>

    </header>
  )
}

export default Header
