import React, { useState, useEffect } from 'react'
import { Link } from 'gatsby';
import Img from "gatsby-image"

import styles from './Carousel.module.scss';

const Carousel = ({slideData}) => {
  const [animate, setAnimate] = useState(false)
  const [index, setIndex] = useState(0);
  const [slides, setSlides ] = useState(slideData)
  const setSlide = () => {
    setAnimate(true)
    setIndex(1)

    setTimeout(() => {
      setAnimate(false)
      setSlides([...slides.slice(1), slides[0]]);
      setIndex(0);
    }, 600)
  }

  useEffect(() => {
    const slideInterval = setInterval(() => {
      setSlide('next');
    }, 5000);

    return () => clearInterval(slideInterval);
  }, [setSlide])


  return (
    <section className={styles.carousel}>
      <ul className={animate ? styles.animate : ''} style={{marginLeft: `calc(100vw * ${index} * -1)`}}>
        {slides.map(({title, button, image, link, alt}) => (
          <li key={title}>
            <Img fluid={image.childImageSharp.fluid} alt={alt} />

            <h1>{title}</h1>

            <Link to={link} className="button primary">{button}</Link>
          </li>
        ))}
      </ul>
    </section>
  )
}

export default Carousel
