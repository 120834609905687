import React from 'react'
import { useStaticQuery, graphql, Link } from 'gatsby';
import { Helmet } from 'react-helmet';
import { Layout } from '..'

const UnderConstruction = () => { 
  const data = useStaticQuery(graphql`
    query {
      construction: file(absolutePath: { regex: "/under-construction.svg/" }) { publicURL },
      spotlight: file(absolutePath: { regex: "/roller.jpg/" }) { childImageSharp { fluid(maxHeight: 400, maxWidth: 900, cropFocus: CENTER) { ...GatsbyImageSharpFluid_withWebp }}},
    }
  `);

  return(
    <Layout headerBreakpoint={44}>
      <Helmet>
        <meta name="robots" content="noindex" />
      </Helmet>

      <section style={{display: 'none'}}></section>

      <section style={{marginTop: '64px'}}>
        <h1>Under Construction</h1>

        <h2>This page will be here very soon!</h2>

        <img src={data.construction.publicURL} />

        <p>
          Thank you for exploring our site! As you can see, this page is currently under construction and we do apologise for the lack of information on the service that you were looking for.
        </p>

        <p>
          We'd like to reassure you that our new shiny website is being worked on actively and will be being updated over the next couple of weeks. Obviously, we'd still love to hear from you; so feel free to contact us!
        </p>
        
        <Link to="/contact" className="button secondary">Contact Us</Link>
      </section>

    </Layout>
  )
}

export default UnderConstruction
