import React from 'react'
import { useStaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';

import styles from './Testimonial.module.scss';

const quotes = [
  'Would highly recommend this tradesmen, polite friendly and efficient. Came to see my problem and was able to fix the problem straight away on the same day. Will be using his services again and would highly recommend to others.',
  'Excellent service as per previous work by this company. Courteous and professional approach.',
  'Excellent service, professional and friendly throughout - highly recommended.',
  'Excellent service from start to finish. On time, worked quickly and kept everything tidy and has done a smashing job.',
  'Fantastic service and very friendly people. Would highly recommend.',
  'From start to finish Gary was very professional. The work was great! Gary is a really lovely fella. The work he completed was to a very high standard. The property was left spotless too. Very happy!',
  'Very professional. Great service. Did a great job',
  'Gary and all his men that visited us were polite and very competent in their jobs. We are really pleased with the finished job.',
  'Brilliant service, quick, reliable, reasonable price. Would recommend 100%.',
  'Gary decorated my hall, stairs and landing area to a exceedingly high standard. Professional from start to finish. Would certainly use their services again. Thank you for a great job done!',
  'Excellent assessment of probable work needed. Carried out next day, on time and at an excellent price and service.',
  'Prompt reply to enquiry, friendly service, great time keeping. Excellent quality of workmanship, very pleased. Highly recommended',
  'Gary and Chris were so polite and a pleasure to have around. Kept everything tidy and clean. Will definitely use again.',
  'Excellent workmanship, courteous and friendly.',
  'Quickly diagnosed and fixed the faults.',
  'Gary was professional, very friendly and helpful with queries and questions I had. Tidy and efficient and would 100% recommend and use again myself.',
  'He was reliable and courteous throughout. He knew his products and gave us good advice on our existing windows.',
  'Gary was excellent in all ways; the quality of the work, his approach to dealing with us, the value of the work, his honesty (there was a choice between repair and new) and his timeliness. We have no hesitation in recommending Gary.',
  'Brilliant team, great work ethic. Best service.',
  'We are very pleased with the standard of workmanship and quality of the work completed. We would have no reservation in recommending G. Parkinson Maintenance.',
  'Arrived on time, very helpful + sorted first time.',
  'The faulty window was repaired immediately within a quick time frame and to an excellent quality. Contact with Gary and his team was efficient and courteous. Would definitely recommend them and will be using them again if I have any future issues.',
  'Very friendly, explained what he was doing and what was wrong. Then completed the job, quickly and efficiently. Great work Chris.',
  'Such a pleasure having them paint our downstairs, work is neat and high standard. Keeps tidy and respects home.',
  'They were so good and competitively priced. I had them back to do this job.',
  'Excellent service as per previous work by this company. Courteous and professional approach.',
  'Superb workmanship. Gary is a very pleasant gentleman. He gave us a quote and delivered exactly as he said he would. Top quality with a smile. He bought along his assistant who is equally as professional. A great job at a very reasonable price. We have no hesitation in recommending this gentleman.'
]

const Testimonial = () => {
  const data = useStaticQuery(graphql`
    query {
      doors: file(absolutePath: { regex: "/doors.jpg/" }) { childImageSharp { fluid(maxWidth: 1080) { ...GatsbyImageSharpFluid_withWebp }}},
      doubleGlazing: file(absolutePath: { regex: "/double-glazing.jpg/" }) { childImageSharp { fluid(maxWidth: 1080) { ...GatsbyImageSharpFluid_withWebp }}},
      glass: file(absolutePath: { regex: "/glass.jpg/" }) { childImageSharp { fluid(maxWidth: 1080) { ...GatsbyImageSharpFluid_withWebp }}},
      glazing: file(absolutePath: { regex: "/window.jpg/" }) { childImageSharp { fluid(maxWidth: 1080) { ...GatsbyImageSharpFluid_withWebp }}},
      interiorDecorating: file(absolutePath: { regex: "/interior-decorating.jpg/" }) { childImageSharp { fluid(maxWidth: 1080) { ...GatsbyImageSharpFluid_withWebp }}},
      kitchen: file(absolutePath: { regex: "/kitchen.jpg/" }) { childImageSharp { fluid(maxWidth: 1080) { ...GatsbyImageSharpFluid_withWebp }}},
      mirror: file(absolutePath: { regex: "/mirror.jpg/" }) { childImageSharp { fluid(maxWidth: 1080) { ...GatsbyImageSharpFluid_withWebp }}},
      roller: file(absolutePath: { regex: "/roller.jpg/" }) { childImageSharp { fluid(maxWidth: 1080) { ...GatsbyImageSharpFluid_withWebp }}},
      splashback: file(absolutePath: { regex: "/splashback.jpg/" }) { childImageSharp { fluid(maxWidth: 1080) { ...GatsbyImageSharpFluid_withWebp }}},
      upvc: file(absolutePath: { regex: "/upvc.jpg/" }) { childImageSharp { fluid(maxWidth: 1080) { ...GatsbyImageSharpFluid_withWebp }}},
      wall: file(absolutePath: { regex: "/wall.jpg/" }) { childImageSharp { fluid(maxWidth: 1080) { ...GatsbyImageSharpFluid_withWebp }}},
      worktops: file(absolutePath: { regex: "/worktops.jpg/" }) { childImageSharp { fluid(maxWidth: 1080) { ...GatsbyImageSharpFluid_withWebp }}},
    }
  `);
  const images = Object.keys(data);
  const selectedQuote = quotes[Math.floor(Math.random() * quotes.length)];
  const selectedImage = data[images[Math.floor(Math.random() * images.length)]];

  return (
    <div className={styles.testimonial}>
      <Img fluid={selectedImage.childImageSharp.fluid} className={styles.img}/>

      <blockquote cite="https://www.checkatrade.com/trades/GParkinsonMaintenanceLtd/reviews">
        "{selectedQuote}"
      </blockquote>
    </div>
  )
}

export default Testimonial
