import React from 'react'
import Img from 'gatsby-image';

import styles from './Spotlight.module.scss';

const Spotlight = ({img}) => (
  <div className={styles.spotlight}>
    <Img fluid={img.childImageSharp.fluid} className={styles.img}/>
  </div>
)

export default Spotlight
